import ReactDOM from "react-dom/client";
import App from "./App";

/* 순서 바꾸지 말 것 (우선순위 문제) */
import "./styles/pc.css";
import "./styles/mobile.css";
import "./styles/tablet.css";
import "./styles/global.css";
import "./styles/mobile-global.css";
import "./styles/tablet-global.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(<App />);
