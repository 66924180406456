import Header from "components/Header";
import Loading from "components/Loading";
import MobileDrawer from "components/MobileDrawer";
import MobileHeader from "components/MobileHeader";
import { DB_1 } from "contants/storage";
import i18n from "locales/i18n";
import { Suspense, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import CustomRouter from "route";
import useGlobalStore from "Store";
import useMediaQuery, { pcQuery } from "utils/useMediaQuery";
import "./styles/animation.css";

const App = () => {
  const { setGlobalCurrentLang, isDrawerOpen } = useGlobalStore();
  const isPc = useMediaQuery(pcQuery);

  // 설정된 언어 확인 및 적용
  useEffect(() => {
    const savedLang = localStorage.getItem(DB_1);

    if (savedLang === "ko" || savedLang === "en") setGlobalCurrentLang(savedLang);
    else setGlobalCurrentLang("ko");

    i18n.changeLanguage(savedLang);
  }, []);

  // 우클릭 방지 제어
  useEffect(() => {
    document.oncontextmenu = function () {
      return false;
    };
  }, []);

  // 모달 오픈 시 스크롤 제어
  useEffect(() => {
    if (!isDrawerOpen) document.body.style.overflowY = "auto";
  }, [isDrawerOpen]);

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Suspense fallback={<Loading />}>
        {!isPc ? <MobileHeader /> : <Header />}

        <CustomRouter />

        {isDrawerOpen && <MobileDrawer />}
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
