import { animeLoading } from "assets/assetStore";
import { Animation } from "./Image";
import useMediaQuery, { mobileQuery } from "utils/useMediaQuery";

/** 로딩 */
const Loading = () => {
  const isMobile = useMediaQuery(mobileQuery);

  return (
    <div className="loading-container">
      <Animation src={animeLoading} width={isMobile ? 60 : 80} height={isMobile ? 60 : 80} />
    </div>
  );
};

export default Loading;
