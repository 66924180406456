import { useEffect, useState } from "react";

export const mobileQuery = "(max-width: 767px)";
export const tabletQuery = "(max-width: 1119px)";
export const pcQuery = "(min-width: 1120px)";

/** 인라인 미디어 쿼리 */
const useMediaQuery = (query: string) => {
  const getMatches = (query: string) => {
    return window.matchMedia(query).matches;
  };

  const [matches, setMatches] = useState<boolean>(getMatches(query));

  const handleChange = () => {
    setMatches(getMatches(query));
  };

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    handleChange();
    matchMedia.addEventListener("change", handleChange);

    return () => {
      matchMedia.removeEventListener("change", handleChange);
    };
  }, [query]);

  return matches;
};

export default useMediaQuery;
