import { animationProps, imageProps } from "interfaces/components/imageInterface";
import Lottie from "react-lottie";

/** 이미지 */
const Image = ({ className, src, width, height, alt, style }: imageProps) => {
  return (
    <img
      className={className}
      src={src}
      width={width}
      height={height}
      alt={alt ? alt : ""}
      style={style}
    />
  );
};

/** 애니메이션 */
const Animation = ({ src, width, height, loop }: animationProps) => {
  const defaultOptions = {
    loop: loop ? loop : true,
    autoPlay: true,
    animationData: src,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="image-animation">
      <Lottie options={defaultOptions} width={width} height={height} />
    </div>
  );
};

export { Animation, Image };
