import {
  drawerClose,
  drawerImageLogo,
  headerAmericaFlag,
  headerAmericaFlagInactive,
  headerKoreaFlag,
  headerKoreaFlagInactive,
} from "assets/assetStore";
import { LOGO_NAME } from "contants/fixed";
import { DB_1 } from "contants/storage";
import i18n from "locales/i18n";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useGlobalStore from "Store";
import { IconButton } from "./Button";
import { Image } from "./Image";

/** 모바일 햄버거 */
const MobileDrawer = () => {
  const { isDrawerOpen, setDrawerOpen, globalCurrentLang, setGlobalCurrentLang } = useGlobalStore();
  const { t } = useTranslation();

  // 페이지 위치에 따른 액티브 상태 반환
  const handleActiveClassName = (loc: string) => {
    if (loc === location.pathname || (loc !== "/" && location.pathname.startsWith(loc)))
      return "active";

    return "";
  };

  // 언어 변경
  const changeLanguage = (lang: string) => {
    localStorage.setItem(DB_1, lang);
    setGlobalCurrentLang(lang);
    i18n.changeLanguage(lang);
  };

  // 햄버거 제어
  const handleDrawer = () => {
    setDrawerOpen(false);
  };

  // 모달 오픈 시 스크롤 제어
  useEffect(() => {
    if (isDrawerOpen) document.body.style.overflowY = "hidden";
  }, [isDrawerOpen]);

  // 화면 크기 전환 시 모달 닫기
  useEffect(() => {
    const handleModalClose = () => {
      if (window.innerWidth >= 1120) setDrawerOpen(false);
    };

    handleModalClose();
    window.addEventListener("resize", handleModalClose);

    return () => window.removeEventListener("resize", handleModalClose);
  }, []);

  return (
    <div className="drawer-background" onClick={handleDrawer}>
      <div className="drawer-content" onClick={(e) => e.stopPropagation()}>
        <p className="drawer-logo">{LOGO_NAME}</p>

        <nav className="drawer-nav-group">
          <Link className={handleActiveClassName("/")} to="/" onClick={handleDrawer}>
            {t(`dict-001`)}
          </Link>
          <Link
            className={handleActiveClassName("/snapshot")}
            to="/snapshot"
            onClick={handleDrawer}
          >
            {t(`dict-002`)}
          </Link>
          <Link
            className={handleActiveClassName("/timeline")}
            to="/timeline"
            onClick={handleDrawer}
          >
            {t(`dict-003`)}
          </Link>
        </nav>

        <div className="drawer-footer">
          <Image className="drawer-footer-logo" src={drawerImageLogo} width={91.76} height={80} />

          <div className="drawer-footer-group">
            <div className="drawer-footer-left">
              <IconButton
                src={globalCurrentLang === "ko" ? headerKoreaFlag : headerKoreaFlagInactive}
                width={36}
                height={24}
                onClick={() => changeLanguage("ko")}
              />

              <IconButton
                src={globalCurrentLang === "en" ? headerAmericaFlag : headerAmericaFlagInactive}
                width={36}
                height={24}
                onClick={() => changeLanguage("en")}
              />
            </div>

            <IconButton src={drawerClose} width={16} height={16} onClick={handleDrawer} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileDrawer;
