import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

const About = lazy(() => import("./pages/About")); // 소개

const Snapshot = lazy(() => import("./pages/Snapshot")); // 스냅샷

const Timeline = lazy(() => import("./pages/Timeline")); // 타임라인

const CustomRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<About />} />
      <Route path="/snapshot" element={<Snapshot />} />
      <Route path="/timeline" element={<Timeline />} />

      <Route path="*" element={<About />} />
    </Routes>
  );
};

export default CustomRouter;
