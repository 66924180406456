import { StoreInterface } from "interfaces/storeInterface";
import { create } from "zustand";

const useGlobalStore = create<StoreInterface>((set) => {
  return {
    // key: initialValue,
    // setKey: (unitKey: unitValueType) => set({ unitKey });
    globalCurrentLang: "ko",
    setGlobalCurrentLang: (lang: string) => set({ globalCurrentLang: lang }),
    isDrawerOpen: false,
    setDrawerOpen: (isDrawerOpen: boolean) => set({ isDrawerOpen: isDrawerOpen }),
  };
});

export default useGlobalStore;
