import { fabArrow } from "assets/assetStore";
import { buttonProps } from "interfaces/components/buttonInterface";
import { Image } from "./Image";
import useMediaQuery, { pcQuery } from "utils/useMediaQuery";

/** 아이콘 버튼 */
const IconButton = ({ src, width, height, style, onClick }: buttonProps) => {
  return (
    <button className="button-icon" onClick={onClick}>
      <Image src={src} width={width ? width : 20} height={height ? height : 20} style={style} />
    </button>
  );
};

/** Secondary 버튼 */
const SecondaryButton = ({ style, text, onClick }: buttonProps) => {
  return (
    <button className="button-secondary" style={style} onClick={onClick}>
      {text}
    </button>
  );
};

/** Primary 버튼 */
const PrimaryButton = ({ style, text, onClick }: buttonProps) => {
  return (
    <button className="button-primary" style={style} onClick={onClick}>
      {text}
    </button>
  );
};

/** Outline 버튼 */
const OutlineSecondaryButton = ({ style, text, onClick }: buttonProps) => {
  return (
    <button className="button-outline" style={style} onClick={onClick}>
      {text}
    </button>
  );
};

/** Fab 버튼 */
const FabButton = ({ style, onClick }: buttonProps) => {
  const isPc = useMediaQuery(pcQuery);

  return (
    <button className="button-fab" onClick={onClick} style={style}>
      <Image src={fabArrow} width={isPc ? 24 : 20} height={isPc ? 24 : 20} />
    </button>
  );
};

export { FabButton, IconButton, OutlineSecondaryButton, PrimaryButton, SecondaryButton };
