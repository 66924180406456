import { headerDrawer } from "assets/assetStore";
import { LOGO_NAME } from "contants/fixed";
import { useEffect, useState } from "react";
import useGlobalStore from "Store";
import { IconButton } from "./Button";

/** 모바일 및 태블릿 헤더 */
const MobileHeader = () => {
  const { setDrawerOpen } = useGlobalStore();
  const [isHeaderActive, setIsHeaderActive] = useState<boolean>(false);

  // 햄버거 제어
  const handleDrawer = () => {
    setDrawerOpen(true);
  };

  // 스크롤 위치에 따라 헤더 배경색 설정
  useEffect(() => {
    const checkScroll = () => {
      if (window.scrollY === 0) setIsHeaderActive(false);
      else setIsHeaderActive(true);
    };

    checkScroll();
    window.addEventListener("scroll", checkScroll);

    return () => window.removeEventListener("scroll", checkScroll);
  }, []);

  return (
    <header className={isHeaderActive ? "active" : ""}>
      <div className="header-content">
        <p className="header-logo">{LOGO_NAME}</p>

        <IconButton src={headerDrawer} width={20} height={20} onClick={handleDrawer} />
      </div>
    </header>
  );
};

export default MobileHeader;
