import {
  headerAmericaFlag,
  headerAmericaFlagInactive,
  headerKoreaFlag,
  headerKoreaFlagInactive,
} from "assets/assetStore";
import { LOGO_NAME } from "contants/fixed";
import { DB_1 } from "contants/storage";
import i18n from "locales/i18n";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import useGlobalStore from "Store";
import { IconButton } from "./Button";

/** 헤더 */
const Header = () => {
  const { t } = useTranslation();
  const { globalCurrentLang, setGlobalCurrentLang } = useGlobalStore();
  const location = useLocation();

  const [isHeaderActive, setIsHeaderActive] = useState<boolean>(false);

  // 언어 변경
  const changeLanguage = (lang: string) => {
    localStorage.setItem(DB_1, lang);
    setGlobalCurrentLang(lang);
    i18n.changeLanguage(lang);
  };

  // 페이지 위치에 따른 액티브 상태 반환
  const handleActiveClassName = (loc: string) => {
    if (loc === location.pathname || (loc !== "/" && location.pathname.startsWith(loc)))
      return "active";

    return "";
  };

  // 스크롤 위치에 따라 헤더 배경색 설정
  useEffect(() => {
    const checkScroll = () => {
      if (window.scrollY === 0) setIsHeaderActive(false);
      else setIsHeaderActive(true);
    };

    checkScroll();
    window.addEventListener("scroll", checkScroll);

    return () => window.removeEventListener("scroll", checkScroll);
  }, []);

  return (
    <header className={isHeaderActive ? "active" : ""}>
      <div className="header-content">
        <p className="header-logo">{LOGO_NAME}</p>

        <nav className="header-nav-group">
          <Link className={handleActiveClassName("/")} to="/">
            {t(`dict-001`)}
          </Link>
          <Link className={handleActiveClassName("/snapshot")} to="/snapshot">
            {t(`dict-002`)}
          </Link>
          <Link className={handleActiveClassName("/timeline")} to="/timeline">
            {t(`dict-003`)}
          </Link>
        </nav>

        <div className="header-flag-group">
          <IconButton
            src={globalCurrentLang === "ko" ? headerKoreaFlag : headerKoreaFlagInactive}
            width={42}
            height={28}
            onClick={() => changeLanguage("ko")}
          />

          <IconButton
            src={globalCurrentLang === "en" ? headerAmericaFlag : headerAmericaFlagInactive}
            width={42}
            height={28}
            onClick={() => changeLanguage("en")}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
